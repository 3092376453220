<template>
  <div style="width: 100%; height: 100%">
    <div style="width: 100%">
      <span
        style="
          font-size: 14px;
          font-weight: 400;
          color: #082254;
          opacity: 0.5;
          margin-right: 13px;
          cursor: pointer;
        "
        @click="toSearch"
        >报表管理/</span
      >
      <span style="font-size: 14px; font-weight: 400; color: #082254"
        >报表详情</span
      >
    </div>
    <div
      style="
        width: 100%;
        text-align: center;
        font-weight: 600;
        color: #082254;
        font-size: 16px;
        line-height: 50px;
        height: 50px;
        margin-top: 10px;
      "
    >
      <!-- 文体中心体育馆-电量统计（kWh） -->
      <!-- 文体中心空调主机能耗统计表 -->
      {{ currentName }}
    </div>
    <div style="width: 100%">
      <!-- <el-table
        :data="tableData"
        style="width: 100%; overflow: auto; margin-top: 30px"
        :row-class-name="tableRowClassName"
        v-loading="loading"
      >
        <el-table-column fixed prop="name" label="监测点位" width="120">
        </el-table-column>
        <el-table-column fixed prop="unit" label="单位" width="120">
        </el-table-column>
      </el-table> -->
      <!-- :border="isborder1" -->
      <el-table
        :data="tableData"
        style="width: 100%; overflow: auto; margin-top: 10px"
        
        :row-class-name="tableRowClassName"
        v-loading="loading"
      >
        <el-table-column
          fixed
          prop="nodeName"
          label="监测点位"
          width="150"
          align="left"
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row.nodeName.split('')[0] === ' '"
              style="margin-left: 20px"
            >
              {{ scope.row.nodeName }}
            </div>
            <div v-else>
              {{ scope.row.nodeName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in tableKey"
          :key="index"
          :label="item"
          align="center"
        >
          <el-table-column
            width="140"
            v-for="(item2, index2) in tableKey2"
            :key="index2"
            :label="item2.name"
            align="center"
          >
            <!-- <template slot-scope="scope"> -->
            <template v-if="item2.child !== null">
              <el-table-column
                width="140"
                v-for="(item3, index3) in item2.child"
                :key="index3"
                :label="item3.name"
                align="center"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row[item3.code][index] === 0"
                    >0</template
                  >
                  <template
                    v-else-if="
                      scope.row[item3.code][index] === undefined ||
                      scope.row[item3.code][index] === null
                    "
                    >--</template
                  >
                  <template v-else>{{ scope.row[item3.code][index] }}</template>
                </template>
              </el-table-column>
            </template>
            <template v-if="item2.child === null" slot-scope="scope">
              <!-- <template > -->
              <!-- {{scope.row}} -->
              <template v-if="scope.row[item2.code][index] === 0">0</template>
              <template
                v-else-if="
                  scope.row[item2.code][index] === undefined ||
                  scope.row[item2.code][index] === null
                "
                >--</template
              >
              <template v-else>{{ scope.row[item2.code][index] }}</template>
              <!-- </template> -->
            </template>
            <!-- </template> -->
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
// import * as echarts from "echarts";
import { getReportDetail } from "@/request/apiLg";
export default {
  filters: {
    formateV: function (value) {
      var date1 = new Date(value);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    formatAType(value) {
      if (value === "OVERRUN") {
        return "数据异常";
      } else if (value === "TIMEOUT") {
        return "通信异常";
      } else if (value === "DIAGNOSIS") {
        return "故障诊断";
      }
    },
  },
  data() {
    return {
      currentName: "",
      tableKey: [],
      tableKey2: [],

      finishTitle: "",
      loading: false,
      active: 2,
      multipleSelection: [],
      tableData: [],
      value1: "",
      isActivenTime: 1,
      detailData: {},
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
  methods: {
    toSearch() {
      this.$router.push({ name: "alarmSearch" });
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    clickNH(index) {
      this.isActivenTime = index;
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  mounted() {
    // getAlarmDetail(this.$route.query.id).then((res) => {
    //   console.log("res", res);
    //   this.detailData = res.data.data;
    //   if (this.detailData.alarmStatus === "UNTREATED") {
    //     this.active = 0;
    //     this.finishTitle = "已处理";
    //   } else if (this.detailData.alarmStatus === "PROCESSED") {
    //     this.active = 3;
    //     this.finishTitle = "已处理";
    //   } else if (this.detailData.alarmStatus === "INGORED") {
    //     this.active = 3;
    //     this.finishTitle = "已忽略";
    //   }
    // });
    getReportDetail(this.$route.query.id).then((res2) => {
      this.loading = false;
      console.log(res2);
      this.tableData = res2.data.data.rows;
      // this.tableKey = res2.data.data.timestamps;
      let temp = [];
      res2.data.data.timestamps.forEach((item) => {
        temp.push(this.formatDate(item));
      });
      this.tableKey = temp;
      this.tableKey2 = res2.data.data.columns;
    });
  },
};
</script>
<style>
.el-step__head.is-success {
  color: #1a7aff;
  border-color: #1a7aff;
}
.el-step__title.is-success {
  color: #1a7aff;
}
</style>
<style scoped>
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.title1 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #f6b084 0%, #ea9b7a 46%, #e97f75 100%);
  border-radius: 4px;
  position: relative;
}
.title2 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #71a1de 0%, #5a82dd 46%, #516adc 100%);
  border-radius: 4px;
  position: relative;
}
.title3 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #b97df9 0%, #ad6bf1 54%, #8c44e1 100%);
  border-radius: 4px;
  position: relative;
}
.title4 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #6bcf84 0%, #6ec887 46%, #2aab4c 100%);
  border-radius: 4px;
  position: relative;
}

.body1 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.body2 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
</style>